<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right style="background-color: rgba(255, 0, 0, 0.5);">
                <div style="padding: 1rem;">
                    <h2 class="pb-5" style="font-weight: 600;">Futures Chart</h2>
                    <h3 class="pb-3">Indicators:<br>
                        <span style="font-size: 12px;">(the following indicators are available) </span>
                    </h3>
                    <h4><b>ATR: </b>Average True Range</h4>
                    <h4><b>BB: </b>Bollinger Bands</h4>
                    <h4><b>COT: </b>Commitment of Traders</h4>
                    <h4><b>MACD: </b>Moving Average Convergence / Divergence</h4>
                    <h4><b>CCI: </b>Commodity Channel Index</h4>
                    <h4><b>RSI: </b>Relative Strange Index</h4>
                    <h4><b>SMA: </b>Simple Moving Average</h4>
                    <h4><b>VOL: </b>Volume and Open Interest</h4>
                    <h4><b>VP: </b>Volume Profile</h4><h4>Seasonals are made using the "average percentage method".</h4>
                    <h3 class="pt-5">Tools:</h3>
                    <h4><b>Show/Hide Label: </b>show or hide information labels on the chart.</h4>
                    <h4><b>P/L (Profit &amp; Loss calculator): </b>to measure the price difference in USD between two points selected with the mouse.</h4>
                    <h4><b>Download and Annotations: </b>accessible via the icon with a symbol at the top left of the graph</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>        

        <table v-if="namespace !== 'undefined'" id="table" border=0 > 
            <tr style="text-align: left; margin-bottom: 0px; vertical-align: bottom;">
                <td style="vertical-align: bottom; padding: 0px 0px 0px 5px; background-color: white; width: 100%">

            <b-container fluid style="width: auto; margin-top: 0px; background-color: white; width: auto; margin-left: -20px;padding: 0 0px !important;border-radius: 10px">
                <b-row align-h="start" align-v="end" style="background-color: white; padding: 1px;border-radius: 10px;" class="row no-gutters">
                    <b-col cols="12" md="auto" style="margin: 0 2px 0 0px; padding: 1px">  
                        <commodity-box v-bind:namespace="namespace">
                            <template v-slot:contract-aligner>
                                <contract-aligner-selector v-bind:namespace="namespace" v-bind:title="'Aligner/Selector'"></contract-aligner-selector>     
                            </template>
                        </commodity-box>
                    </b-col>
                    <b-col>
                        <b-button-group style="margin: 2px 1px 1px 1px; ">
                            <trade-specs v-bind:namespace="namespace" style=" margin: 0px 1px 0px 1px"></trade-specs> 
        
                            <stock-graph-type-toggle v-bind:namespace="namespace" v-if="legs === 1" 
                                    v-bind:click-handler="'am4charts.changeStockGraphType'" style="margin: 0px 0px 0 1px"></stock-graph-type-toggle>
                            <indicators v-bind:namespace="namespace" style="margin: 0px 1px 0px 1px"></indicators>
                       
                            <horizontal-scrollbar-toggle v-bind:namespace="namespace" style="margin: 1px"></horizontal-scrollbar-toggle>
                            <seasonal-selector v-bind:namespace="namespace"></seasonal-selector>
                            <period-selector v-bind:namespace="namespace"></period-selector>
                        </b-button-group>
                        <b-button-group style="border: 1px solid gray; background-color: #E8E8E8; margin: 2px 1px 1px 1px; border-radius: 3px;">
                            <toggle-single-throw v-bind:namespace="namespace" v-if="normalizedStockGraphType === 'line'"
                                                 v-bind:property="'showBullets'" v-bind:label="'bullets'" style="margin: 0px;"></toggle-single-throw>
                            <balloon-toggle v-bind:namespace="namespace" style="margin: 1px 0 0 0px;"></balloon-toggle>
                            <indicator-button v-bind:namespace="namespace" indicator="p/l" label="P/L" style="margin: 0px;"></indicator-button>
                            <toggle-single-throw v-if="['admin', 'superadmin'].includes($store.state.user.role)" v-bind:namespace="namespace"
                                                 v-bind:property="'showPlaybackControl'" v-bind:label="'playback'" style="margin: 0px; display: none"></toggle-single-throw>
                            <add-to-portfolio-button v-bind:namespace="namespace" style="margin: 1px 0 0 0"></add-to-portfolio-button>                            
                            <b-button v-b-toggle.sidebar-help variant="warning" size="sm" style="border: 0px solid darkgray;  margin: 1px;">Help</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-container>
            </td>
            </tr>
        </table>                   
    </div>
</template>

<style>
    #id {
        border-spacing: 10px;
        border-collapse: separate;
    }
</style>

<script>
    import commodityBox from '@/components/commodity-box';
    import contractAlignerSelector from '@/components/contract-aligner-selector';
    import seasonalSelector from '@/components/seasonal-selector';
    import periodSelector from '@/components/period-selector';
    import toggleSingleThrow from '@/components/toggle-single-throw';
    import indicatorButton from '@/components/indicator-button';
    import indicators from '@/components/indicators';
    import addToPortfolioButton from '@/components/add-to-portfolio-button';
    import stockGraphTypeToggle from '@/components/stock-graph-type-toggle';
    import balloonToggle from '@/components/balloon-toggle';
    import horizontalScrollbarToggle from '@/components/horizontal-scrollbar-toggle';
    import tradeSpecs from '@/components/trade-specs';

    export default {
        components: {
            commodityBox,
            contractAlignerSelector,
            seasonalSelector,
            periodSelector,
            toggleSingleThrow,
            indicators,
            addToPortfolioButton,
            stockGraphTypeToggle,
            balloonToggle,
            horizontalScrollbarToggle,
            tradeSpecs,
            indicatorButton
        },
        created() {

        },
        mounted() {
            // console.log("basic-chart-input.vue mounted() starting. this.namespace=", this.namespace);

            let observer = new ResizeObserver(entries => {
                // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                    // console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                        // console.log("entry.contentRect.height=", entry.contentRect.height);
                        this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                    }
                }
            });
            observer.observe(document.querySelector('#' + this.namespace + 'InputDiv'));
        },
        props: ['namespace'],
        data: function () {
            return{
                dataSourceOptions: [
                    {value: 'barchart', text: 'barchart'},
                    {value: 'eod', text: 'eod'}
                ]
            }
        },
        computed: {
            symbols() {
                return this.$store.state.user.symbols;
            },
            normalizedStockGraphType() {
                return this.$store.getters[this.namespace + "/normalizedStockGraphType"];
            },
            tickerInputType() {
                return this.user.tickerInputType;
            },
            cols() {
                return this.tickerInputType === 'text' ? 9 : 4;
            },
            legs(){
                return this.$store.state[this.namespace].legs;
            }
        }
    };

</script>
